// src/components/Contacts.js

import React, { useState, useEffect } from "react";
import { ref, push, onValue, onDisconnect, set, remove } from "firebase/database";
import { auth, realtimeDB } from "../firebaseConfig";

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [newContact, setNewContact] = useState({ name: "", email: "" });
  const [onlineStatus, setOnlineStatus] = useState({});
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    if (!auth.currentUser) {
      console.error("User is not logged in.");
      return;
    }

    const userId = auth.currentUser.uid;
    const contactsRef = ref(realtimeDB, `contacts/${userId}`);
    const presenceRef = ref(realtimeDB, `presence/${userId}`);
    const globalPresenceRef = ref(realtimeDB, "presence");

    // Mark user online and handle disconnects
    onDisconnect(presenceRef).set({ status: "offline", lastSeen: Date.now() });
    set(presenceRef, { status: "online", lastSeen: Date.now() })
      .catch((error) => console.error("Error setting presence:", error));

    // Fetch contacts
    const unsubscribeContacts = onValue(contactsRef, (snapshot) => {
      const data = snapshot.val();
      const contactsArray = data
        ? Object.entries(data).map(([id, details]) => ({
            id,
            ...details,
          }))
        : [];
      setContacts(contactsArray);
    });

    // Track online status for all users
    const unsubscribePresence = onValue(globalPresenceRef, (snapshot) => {
      setOnlineStatus(snapshot.val() || {});
      console.log("Online Status Object:", snapshot.val());
    });

    return () => {
      unsubscribeContacts();
      unsubscribePresence();
      remove(presenceRef).catch((error) =>
        console.error("Error removing presence on unmount:", error)
      );
    };
  }, []);

  const handleAddContact = () => {
    if (!auth.currentUser) {
      console.error("User is not logged in.");
      setFeedback("Error: User is not logged in.");
      return;
    }

    const userId = auth.currentUser.uid;
    const contactsRef = ref(realtimeDB, `contacts/${userId}`);

    if (!newContact.name.trim() || !newContact.email.trim()) {
      setFeedback("Please enter both name and email.");
      return;
    }

    push(contactsRef, newContact)
      .then(() => {
        setNewContact({ name: "", email: "" });
        setFeedback("Contact added successfully!");
      })
      .catch((error) => {
        console.error("Error adding contact:", error);
        setFeedback("Error adding contact. Please try again.");
      });
  };

  const handleRemoveContact = (contactId) => {
    if (!auth.currentUser) {
      console.error("User is not logged in.");
      return;
    }

    const userId = auth.currentUser.uid;
    const contactRef = ref(realtimeDB, `contacts/${userId}/${contactId}`);

    remove(contactRef)
      .then(() => {
        setFeedback("Contact removed successfully!");
      })
      .catch((error) => {
        console.error("Error removing contact:", error);
        setFeedback("Error removing contact. Please try again.");
      });
  };

  return (
    <div>
      <h2>Your Contacts</h2>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {contacts.map((contact) => (
          <li key={contact.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <span style={{ flexGrow: 1 }}>
              {contact.name} ({contact.email})
              {onlineStatus[contact.id]?.status?.status === "online" ? (
                <span style={{ color: "green", marginLeft: "10px" }}>● Online</span>
              ) : (
                <span style={{ color: "gray", marginLeft: "10px" }}>● Offline</span>
              )}
            </span>
            <button
              style={{
                backgroundColor: "white",
                color: "red",
                border: "1px solid red",
                padding: "5px 10px",
                cursor: "pointer",
                borderRadius: "4px",
                fontSize: "14px",
                marginLeft: "10px",
              }}
              onClick={() => handleRemoveContact(contact.id)}
            >
              Remove Contact
            </button>
          </li>
        ))}
        {/* Display your own online status */}
        <li style={{ display: "flex", alignItems: "center", marginBottom: "10px", fontWeight: "bold" }}>
          <span>
            You ({auth.currentUser?.email})
            {onlineStatus[auth.currentUser?.uid]?.status?.status === "online" ? (
              <span style={{ color: "green", marginLeft: "10px" }}>● Online</span>
            ) : (
              <span style={{ color: "gray", marginLeft: "10px" }}>● Offline</span>
            )}
          </span>
        </li>
      </ul>
      <div>
        <input
          type="text"
          placeholder="Name"
          value={newContact.name}
          onChange={(e) => setNewContact({ ...newContact, name: e.target.value })}
          style={{ marginRight: "10px" }}
        />
        <input
          type="email"
          placeholder="Email"
          value={newContact.email}
          onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
          style={{ marginRight: "10px" }}
        />
        <button onClick={handleAddContact}>Add Contact</button>
        {feedback && <p style={{ color: feedback.includes("Error") ? "red" : "green" }}>{feedback}</p>}
      </div>
    </div>
  );
}

export default Contacts;
