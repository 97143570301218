import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useParams, BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { createSignalingServer } from './webrtc-signaling';
import { createPeerConnection, handleOffer, handleAnswer, handleICECandidate } from './PeerConnection';
import { startScreenShare, stopScreenShare } from './screenShare';
import Chat from './Chat';
import Contacts from './components/Contacts';
import DatabaseTest from './components/DatabaseTest';
import Auth from './Auth';
import './App.css';

function MeetingRoom() {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const signalingServer = useRef(null);
  const peerConnection = useRef(null);
  const localVideoRef = useRef(null);
  const localStream = useRef(null);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [participantName, setParticipantName] = useState('');
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [roomUrl, setRoomUrl] = useState('');

  const handleNameSubmit = useCallback((e) => {
    e.preventDefault();
    if (participantName.trim()) {
      setIsNameEntered(true);
      initializeConnection();
    }
  }, [participantName]);

  const handleRoomState = useCallback((participants) => {
    console.log('Room participants:', participants);
  }, []);

  const handleParticipantJoined = useCallback((participant) => {
    console.log('New participant joined:', participant);
  }, []);

  const getLocalStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { 
          width: { ideal: 1280 },
          height: { ideal: 720 },
          frameRate: { ideal: 30 }
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });

      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
      localStream.current = stream;
      return stream;
    } catch (error) {
      console.error('Error accessing media devices:', error);
      setConnectionError(
        error.name === 'NotAllowedError'
          ? 'Please allow camera and microphone access'
          : 'Unable to access media devices'
      );
      return null;
    }
  }, []);

  const initializeConnection = useCallback(async () => {
    try {
      const stream = await getLocalStream();
      if (!stream) return;

      const configuration = {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:stun1.l.google.com:19302' }
        ],
        iceCandidatePoolSize: 10
      };

      peerConnection.current = new RTCPeerConnection(configuration);

      stream.getTracks().forEach(track => {
        peerConnection.current.addTrack(track, stream);
      });

      peerConnection.current.onicecandidate = (event) => {
        if (event.candidate && signalingServer.current) {
          signalingServer.current.send({
            type: 'ice',
            candidate: event.candidate
          });
        }
      };

      peerConnection.current.ontrack = (event) => {
        const stream = event.streams[0];
        setRemoteStreams(prev => {
          if (!prev.find(s => s.id === stream.id)) {
            return [...prev, stream];
          }
          return prev;
        });
      };

      peerConnection.current.oniceconnectionstatechange = () => {
        if (peerConnection.current.iceConnectionState === 'failed') {
          peerConnection.current.restartIce();
        }
      };

      signalingServer.current = createSignalingServer('ws://34.58.144.34:3001', async (message) => {
        try {
          switch (message.type) {
            case 'offer':
              await handleOffer(peerConnection.current, message.sdp, signalingServer.current);
              break;
            case 'answer':
              await handleAnswer(peerConnection.current, message.sdp);
              break;
            case 'ice':
              if (message.candidate) {
                await handleICECandidate(peerConnection.current, message.candidate);
              }
              break;
            case 'roomState':
              handleRoomState(message.participants);
              break;
            case 'participantJoined':
              handleParticipantJoined(message.participant);
              break;
            default:
              console.warn('Unhandled message type:', message.type);
          }
        } catch (error) {
          console.error('Error handling signaling message:', error);
          setConnectionError('Connection error occurred');
        }
      });

      signalingServer.current.joinRoom(roomId, participantName);
    } catch (error) {
      console.error('Error initializing connection:', error);
      setConnectionError('Failed to establish connection');
    }
  }, [roomId, participantName, getLocalStream, handleRoomState, handleParticipantJoined]);

  useEffect(() => {
    if (!roomId) {
      const newRoomId = Math.random().toString(36).substring(2, 15);
      setIsHost(true);
      navigate(`/room/${newRoomId}`);
    } else {
      setRoomUrl(`${window.location.origin}/room/${roomId}`);
    }
  }, [roomId, navigate]);

  useEffect(() => {
    return () => {
      if (localStream.current) {
        localStream.current.getTracks().forEach(track => track.stop());
      }
      if (signalingServer.current) {
        signalingServer.current.close();
      }
      if (peerConnection.current) {
        peerConnection.current.close();
      }
    };
  }, []);

  if (!isNameEntered) {
    return (
      <div className="name-prompt">
        <h2>Enter Your Name to Join the Room</h2>
        <form onSubmit={handleNameSubmit}>
          <input
            type="text"
            value={participantName}
            onChange={(e) => setParticipantName(e.target.value)}
            placeholder="Your Name"
            required
          />
          <button type="submit">Join Room</button>
        </form>
      </div>
    );
  }

  return (
    <div className="meeting-room">
      <h1>Verdantoff Live Video Streaming</h1>
      <h2>Meeting Room: {roomId}</h2>
      <h3>Welcome, {participantName}!</h3>
      {connectionError && (
        <div className="error-message">
          {connectionError}
          <button onClick={() => setConnectionError(null)}>Retry</button>
        </div>
      )}
      <div className="video-grid">
        <video ref={localVideoRef} autoPlay playsInline muted className="video-frame" />
        {remoteStreams.map((stream) => (
          <video
            key={stream.id}
            autoPlay
            playsInline
            className="video-frame"
            ref={(videoElement) => {
              if (videoElement) videoElement.srcObject = stream;
            }}
          />
        ))}
      </div>
      <div className="button-container">
        <button onClick={() => {
          if (isScreenSharing) {
            stopScreenShare(localVideoRef);
            setIsScreenSharing(false);
          } else {
            startScreenShare(localVideoRef);
            setIsScreenSharing(true);
          }
        }}>
          {isScreenSharing ? 'Stop Sharing' : 'Share Screen'}
        </button>
        <button onClick={() => setIsChatVisible(!isChatVisible)}>
          {isChatVisible ? 'Close Chat' : 'Open Chat'}
        </button>
        <button onClick={() => signOut(auth)}>Sign Out</button>
      </div>
      {isHost && (
        <div className="room-url">
          <p>Meeting Room URL: {roomUrl}</p>
          <button onClick={() => navigator.clipboard.writeText(roomUrl)}>
            Copy Room URL
          </button>
        </div>
      )}
      {isChatVisible && (
        <div className="chat-container">
          <Chat />
        </div>
      )}
    </div>
  );
}

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="app">
        <nav>
          <Link to="/">Home</Link> | <Link to="/contacts">Contacts</Link>
        </nav>
        <Routes>
          <Route path="/" element={user ? <MeetingRoom /> : <Auth />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/room/:roomId" element={user ? <MeetingRoom /> : <Auth />} />
        </Routes>
        <DatabaseTest />
      </div>
    </Router>
  );
}

export default App;
