export function createSignalingServer(url, onMessageCallback) {
  let socket = null;
  let isSocketOpen = false;
  let messageQueue = [];
  let reconnectAttempts = 0;
  const MAX_RECONNECT_ATTEMPTS = 10;
  const RECONNECT_DELAY = 1000;
  const signalStates = new Map();
  const ONE_HOUR = 3600000;

  if (!url.startsWith('ws://') && !url.startsWith('wss://')) {
    throw new Error('Invalid WebSocket URL protocol');
  }

  async function checkServerAvailability() {
    try {
      const response = await fetch(url.replace('ws:', 'http:') + '/health');
      return response.ok;
    } catch {
      return false;
    }
  }

  async function connect() {
    if (socket && socket.readyState !== WebSocket.CLOSED) {
      socket.close();
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    try {
      socket = new WebSocket(url);
      
      socket.onopen = () => {
        console.log('Connected to signaling server:', {
          url,
          timestamp: new Date().toISOString()
        });
        isSocketOpen = true;
        reconnectAttempts = 0;
        processMessageQueue();
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', {
          error,
          url,
          readyState: socket.readyState,
          timestamp: new Date().toISOString()
        });
        isSocketOpen = false;
      };

      socket.onclose = () => {
        isSocketOpen = false;
        attemptReconnect();
      };

      socket.onmessage = handleMessage;
    } catch (error) {
      console.error('Connection error:', error);
      attemptReconnect();
    }
  }

  function validateMessage(message) {
    if (!message.type) return false;
    switch (message.type) {
      case 'signal':
        return message.roomId && 
               message.signal &&
               message.targetParticipant &&
               typeof message.signal === 'object' &&
               ['offer', 'answer', 'candidate'].includes(message.signal.type) &&
               ((message.signal.type === 'candidate' && message.signal.candidate) ||
                (message.signal.type === 'offer' && message.signal.sdp) ||
                (message.signal.type === 'answer' && message.signal.sdp));
      case 'join':
        return message.roomId && message.participantName;
      default:
        return false;
    }
  }

  function processMessageQueue() {
    if (!isSocketOpen) {
      console.warn('Cannot process queue: socket not open');
      if (messageQueue.length > 100) {
        messageQueue.splice(0, messageQueue.length - 100);
      }
      return;
    }

    while (messageQueue.length > 0) {
      const message = messageQueue[0];
      if (!validateMessage(message)) {
        console.error('Invalid message in queue:', message);
        messageQueue.shift();
        continue;
      }
      try {
        const messageString = JSON.stringify(message);
        socket.send(messageString);
        messageQueue.shift();
      } catch (error) {
        console.error('Failed to send message:', error);
        break;
      }
    }
  }

  function attemptReconnect() {
    if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
      const delay = RECONNECT_DELAY * Math.pow(1.5, reconnectAttempts);
      console.log(`Reconnection attempt ${reconnectAttempts + 1}/${MAX_RECONNECT_ATTEMPTS} in ${delay}ms`);
      setTimeout(() => {
        reconnectAttempts++;
        connect();
      }, delay);
    } else {
      console.error('Max reconnection attempts reached');
      onMessageCallback({
        type: 'connectionError',
        message: 'Connection failed after maximum attempts'
      });
    }
  }

  function handleMessage(message) {
    try {
      const data = JSON.parse(message.data);
      if (data.type === 'error') {
        console.error('Server error:', data.message, data.code);
        return;
      }
      if (data.type === 'signal') {
        updateSignalState(data.roomId, data.signal, 'received');
      }
      onMessageCallback(data);
    } catch (error) {
      console.error('Error parsing message:', error);
    }
  }

  function updateSignalState(roomId, signal, state) {
    const MAX_SIGNALS_PER_ROOM = 100;
    if (!signalStates.has(roomId)) {
      signalStates.set(roomId, []);
    }
    const signals = signalStates.get(roomId);
    signals.push({
      signal: {
        type: signal.type,
        ...(signal.type === 'candidate' ? {candidate: signal.candidate} : {sdp: signal.sdp})
      },
      state,
      timestamp: Date.now()
    });
    if (signals.length > MAX_SIGNALS_PER_ROOM) {
      signals.splice(0, signals.length - MAX_SIGNALS_PER_ROOM);
    }
  }

  function cleanupSignalStates() {
    const now = Date.now();
    for (const [roomId, states] of signalStates.entries()) {
      const filtered = states.filter(state => now - state.timestamp < ONE_HOUR);
      if (filtered.length === 0) {
        signalStates.delete(roomId);
      } else {
        signalStates.set(roomId, filtered);
      }
    }
  }

  const api = {
    send: (message) => {
      if (!validateMessage(message)) {
        console.error('Invalid message format:', message);
        return;
      }
      if (isSocketOpen && socket?.readyState === WebSocket.OPEN) {
        try {
          const messageString = JSON.stringify(message);
          socket.send(messageString);
          if (message.type === 'signal') {
            updateSignalState(message.roomId, message.signal, 'sent');
          }
        } catch (error) {
          console.error('Error sending message:', error);
          messageQueue.push(message);
        }
      } else {
        messageQueue.push(message);
        if (!isSocketOpen) {
          connect();
        }
      }
    },
    joinRoom: (roomId, participantName) => {
      if (!roomId || typeof roomId !== 'string') {
        throw new Error('Invalid roomId provided');
      }
      if (!participantName || typeof participantName !== 'string') {
        throw new Error('Participant name is required');
      }
      api.send({ type: 'join', roomId, participantName });
    },
    sendSignal: (roomId, signal, targetParticipant) => {
      if (!roomId || typeof roomId !== 'string') {
        throw new Error('Invalid roomId provided');
      }
      if (!signal || typeof signal !== 'object') {
        throw new Error('Invalid signal provided');
      }
      if (!targetParticipant || typeof targetParticipant !== 'string') {
        throw new Error('Target participant is required');
      }
      api.send({ type: 'signal', roomId, signal, targetParticipant });
    },
    close: () => {
      isSocketOpen = false;
      if (socket) {
        socket.close();
      }
    }
  };

  // Initialize connection with availability check
  checkServerAvailability().then(available => {
    if (available) {
      connect();
      setInterval(cleanupSignalStates, ONE_HOUR);
    } else {
      console.error('Signaling server not available');
      attemptReconnect();
    }
  });

  return api;
}
