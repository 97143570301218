// src/PeerConnection.js

const LogLevel = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3
};

function log(level, message, ...args) {
  const timestamp = new Date().toISOString();
  switch (level) {
    case LogLevel.DEBUG:
      console.debug(`[${timestamp}] DEBUG:`, message, ...args);
      break;
    case LogLevel.INFO:
      console.log(`[${timestamp}] INFO:`, message, ...args);
      break;
    case LogLevel.WARN:
      console.warn(`[${timestamp}] WARN:`, message, ...args);
      break;
    case LogLevel.ERROR:
      console.error(`[${timestamp}] ERROR:`, message, ...args);
      break;
  }
}

async function fetchTurnCredentials() {
  try {
    const response = await fetch('/get-turn-credentials', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    log(LogLevel.ERROR, 'Error fetching TURN credentials:', error);
    return {
      urls: ['turn:verdantoff.com:3478'],
      username: 'webrtc',
      credential: '9f2d7a1b7153e8cba1c4f67d84ea7c60',
      credentialType: 'password'
    };
  }
}

async function createPeerConnection(signalingServer) {
  const MAX_ICE_RESTART_ATTEMPTS = 3;
  let iceRestartAttempts = 0;

  try {
    const turnCredentials = await fetchTurnCredentials();
    const configuration = {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        turnCredentials
      ],
      iceTransportPolicy: 'all',
      iceCandidatePoolSize: 10,
      bundlePolicy: 'max-bundle'
    };

    const pc = new RTCPeerConnection(configuration);

    pc.oniceconnectionstatechange = () => {
      log(LogLevel.INFO, 'ICE Connection state:', pc.iceConnectionState);
      if (pc.iceConnectionState === 'failed') {
        if (iceRestartAttempts < MAX_ICE_RESTART_ATTEMPTS) {
          iceRestartAttempts++;
          pc.restartIce();
          log(LogLevel.WARN, `ICE restart attempt ${iceRestartAttempts}/${MAX_ICE_RESTART_ATTEMPTS}`);
        }
      }
    };

    pc.onicecandidate = (event) => {
      if (event.candidate) {
        signalingServer.send({
          type: 'ice',
          candidate: event.candidate
        });
      }
    };

    return pc;
  } catch (error) {
    log(LogLevel.ERROR, 'Error creating peer connection:', error);
    throw error;
  }
}

async function handleOffer(pc, offer, signalingServer) {
  try {
    await pc.setRemoteDescription(new RTCSessionDescription(offer));
    const answer = await pc.createAnswer();
    await pc.setLocalDescription(answer);
    signalingServer.send({
      type: 'answer',
      sdp: answer.sdp
    });
  } catch (error) {
    log(LogLevel.ERROR, 'Error handling offer:', error);
    throw error;
  }
}

async function handleAnswer(pc, answer) {
  try {
    await pc.setRemoteDescription(new RTCSessionDescription(answer));
  } catch (error) {
    log(LogLevel.ERROR, 'Error handling answer:', error);
    throw error;
  }
}

async function handleICECandidate(pc, candidate) {
  try {
    if (candidate && pc.remoteDescription) {
      await pc.addIceCandidate(new RTCIceCandidate(candidate));
    }
  } catch (error) {
    log(LogLevel.ERROR, 'Error handling ICE candidate:', error);
  }
}

function handleWebRTCError(error, context) {
  log(LogLevel.ERROR, `WebRTC error in ${context}:`, error);
}

export {
  createPeerConnection,
  handleOffer,
  handleAnswer,
  handleICECandidate,
  handleWebRTCError
};
